<script setup>
import VaildationInputListVue from '@/components/ui/VaildationInputList.vue'
import { findPw } from '@/api/auth'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { validateLoginId } from '@/util/reg-validators'

const router = useRouter()
const vaildation = ref(null)
const notExist = ref(false)

const validationInputs = [
  {
    type: 'text',
    placeholder: '아이디를 입력해주십시오',
    isValidFunc: ({ value }) => validateLoginId(value),
    vaildText: '',
    invalidText: '10 자리 내의 영문자, 숫자만 입력가능합니다.'
  }
]

const formSubmitHandler = async (e) => {
  notExist.value = false

  if (!vaildation.value.vaildate()) return

  const [id] = vaildation.value.submitData
  const result = await findPw(id.value)

  // 가입된 email이 아닐때
  if (result.code && result.code === 10001) return (notExist.value = true)

  alert('임시비밀번호가 발급되었습니다.')
  return router.replace('/')
}
</script>

<template>
  <article class="wf__login-form">
    <div class="wf__login-form-title-wrapper">
      <h4 class="wf__login-form-title">비밀번호찾기</h4>
      <p class="wf__login-form-description">회원가입시 기입한 이메일로 임시비밀번호가 발급됩니다.</p>
    </div>

    <form class="wf__login-form-body" @submit.prevent="formSubmitHandler">
      <section class="wf__login-form-input-wrapper">
        <VaildationInputListVue ref="vaildation" :items="validationInputs" />
      </section>

      <div v-if="notExist">존재하지 않는 사용자입니다.</div>
      <button class="wf__login-form-submit" type="submit">임시비밀번호</button>
    </form>
  </article>
</template>

<style scoped>
.wf__login-form {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.wf__login-form,
.wf__login-form-title-wrapper,
.wf__login-form-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.wf__login-form-title-wrapper {
  gap: 0.5rem;
}
.wf__login-form-title {
  font-size: 36px;
  font-weight: bold;
}
.wf__login-form-subtitle {
  font-size: 24px;
  color: var(--wf-secondary-color);
}
.wf__login-form-description {
  font-size: 16px;
  color: var(--wf-gray-color);
}

.wf__login-form-body {
  align-items: center;

  width: 100%;
  max-width: 468px;

  row-gap: 3rem;
}
.wf__login-form-input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.wf__login-form-redirects {
  display: flex;
  gap: 0.5em;
}

.wf__login-form-submit {
  outline: none;
  border: none;
  color: #fff;
  background-color: var(--wf-primary-color);
  border-radius: 0.25em;
  letter-spacing: 0.5px;
  font-weight: 400;
  padding: 0.35em 1em;
}
.wf__login-form-submit:hover {
  opacity: 0.8;
}
</style>
