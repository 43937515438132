<script setup>
import { defineProps, ref, defineExpose } from 'vue'

const submitData = ref([])
const setSubmitData = (el) => {
  submitData.value.push(el)
}

const props = defineProps({
  // Array<Object>
  // => type: String
  // => placeholder: String
  // => isValidFunc: Function
  // => vaildText: String
  // => invalidText: String
  items: Array
})

// INFO: 부모 component에서 사용할 수 있는 method정의
defineExpose({
  vaildate: () => {
    const validatorItems = submitData.value.map((item, idx) => {
      const additional = props.items[idx].isValidFunc
      return { el: item, isValidFunc: additional }
    })

    for (const item of validatorItems) {
      item.el.classList.remove('invalid')
      item.el.classList.remove('valid')

      if (!item.isValidFunc(item.el)) {
        item.el.classList.add('invalid')
        item.el.focus()
        return false
      }

      item.el.classList.add('valid')
    }

    return true
  },
  submitData
})
</script>

<template>
  <div class="wf__vaildation" v-for="(item, i) in items" :key="i">
    <label class="wf__vaildation-label" v-if="item.label" for="">{{ item.label }}</label>

    <div class="wf__vaildation-wrapper">
      <input class="wf__vaildation-input" :ref="setSubmitData" :type="item.type" :placeholder="item.placeholder" />
      <span class="wf__vaildation-valid-text">{{ item.validText }}</span>
      <span class="wf__vaildation-invalid-text">{{ item.invalidText }}</span>
    </div>
  </div>
</template>

<style scoped>
.wf__vaildation {
  display: flex;
  align-items: center;
  gap: 1em;
}
.wf__vaildation-label {
  color: var(--wf-primary-color);
}

.wf__vaildation-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.wf__vaildation-input {
  outline: none;
  border: 1px solid var(--wf-gray-color);
  border-radius: 0.5em;
  padding: 0.5em 1em;
}
.wf__vaildation-input ~ .wf__vaildation-invalid-text {
  display: none;
}
.wf__vaildation-input ~ .wf__vaildation-valid-text {
  display: none;
}

.wf__vaildation-input.invalid {
  border-color: var(--wf-error-color);
}
.wf__vaildation-input.valid {
  border-color: var(--wf-primary-color);
}
.wf__vaildation-input.invalid ~ .wf__vaildation-invalid-text {
  display: block;
  color: var(--wf-error-color);
}
.wf__vaildation-input.valid ~ .wf__vaildation-valid-text {
  display: block;
  color: var(--wf-primary-color);
}
</style>
